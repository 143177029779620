import axios from "axios";
import config from "../config";
import localStorageService from "../services/localStorageService";
import store from "../store";

let subscriptions = new Object({
  overrideOrderItem: (order_id, name) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/subscriptions/overrideOrderItem",
        data: {
          backend: store.state.backend,
          order_id: order_id,
          name: name
        },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchStatistics: (int_shipment_id, it_shipment_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/subscriptions/statistics",
        data: {
          int_shipment_id: int_shipment_id,
          it_shipment_id: it_shipment_id
        },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchExports: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/subscriptions/fetchExports",
        data: { ctx: ctx, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.exports,
            totalCount: resp.data.responseData.count
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchExportsDetails: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/subscriptions/fetchExportsDetails",
        data: { ctx: ctx, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.exports,
            totalCount: resp.data.responseData.count,
            errorTickets: resp.data.responseData.errorTickets,
            warningTickets: resp.data.responseData.warningTickets,
            noticeTickets: resp.data.responseData.noticeTickets
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  buildExport: (subscriptions, shipment, warehouse_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/subscriptions/buildExport",
        data: {
          subscriptions: subscriptions,
          shipment: shipment,
          backend: store.state.backend,
          warehouse_id: warehouse_id
        },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  setNotes: (id, notes) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/subscriptions/setNotes",
        data: { id: id, notes: notes, backend: store.state.backend },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  setExportDescription: (id, description) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/subscriptions/setExportDescription",
        data: {
          id: id,
          description: description,
          backend: store.state.backend
        },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  setSuspendState: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/subscriptions/suspend",
        data: { id: id, backend: store.state.backend },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  removeSuspendState: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/subscriptions/unsuspend",
        data: { id: id, backend: store.state.backend },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  setManualState: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/subscriptions/setManual",
        data: { id: id, backend: store.state.backend },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  removeManualState: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/subscriptions/unsetManual",
        data: { id: id, backend: store.state.backend },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchSubscriptions: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/subscriptions/fetch",
        data: { ctx: ctx, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.subscriptions,
            totalCount: resp.data.responseData.count
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchOrders: (id, orders_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/subscriptions/fetchOrders",
        data: {
          subscription_id: id,
          orders_id: orders_id,
          backend: store.state.backend
        },
        method: "POST"
      })
        .then(resp => {
          resolve({ items: resp.data.responseData.orders });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchShipments: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/shipments/fetch",
        data: { ctx: ctx, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.shipments,
            totalCount: resp.data.responseData.count
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchShipmentsFilters: backend => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/shipments/fetchFilters",
        data: { backend: backend ? backend : store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve({ items: resp.data.responseData.shipments });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  addShipment: item => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/shipments/add",
        data: { shipment: item, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  editShipment: item => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/shipments/edit",
        data: { shipment: item, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  removeShipment: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/shipments/remove",
        data: { id: id, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  downloadExportCSV: exportID => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/subscriptions/downloadExportCSV?token=" +
          encodeURI(token) +
          "&backend=" +
          encodeURI(store.state.backend) +
          "_" +
          "&exportID=" +
          exportID
      );
      link.setAttribute("target", "_blank");
      let filename =
        "roscioli_wc_" + store.state.backend + "_shipments_export.csv";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  downloadExportInvoices: exportID => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/subscriptions/downloadExportInvoices?token=" +
          encodeURI(token) +
          "&backend=" +
          encodeURI(store.state.backend) +
          "_" +
          "&exportID=" +
          exportID
      );
      link.setAttribute("target", "_blank");
      let filename =
        "roscioli_wc_" + store.state.backend + "invoices_export.xlsl";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  downloadExportMOS: exportID => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/subscriptions/downloadExportMOS?token=" +
          encodeURI(token) +
          "&backend=" +
          encodeURI(store.state.backend) +
          "_" +
          "&exportID=" +
          exportID
      );
      link.setAttribute("target", "_blank");
      let filename =
        "roscioli_wc_" + store.state.backend + "shipment_mos_export.zip";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  downloadExportExcel: exportID => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/subscriptions/downloadExportExcel?token=" +
          encodeURI(token) +
          "&backend=" +
          encodeURI(store.state.backend) +
          "_" +
          "&exportID=" +
          exportID
      );
      link.setAttribute("target", "_blank");
      let filename =
        "roscioli_wc_" + store.state.backend + "shipments_export.xlsx";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  downloadExportCourierExcel: exportID => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/subscriptions/downloadExportCourierExcel?token=" +
          encodeURI(token) +
          "&backend=" +
          encodeURI(store.state.backend) +
          "_" +
          "&exportID=" +
          exportID
      );
      link.setAttribute("target", "_blank");
      let filename =
        "roscioli_wc_" + store.state.backend + "shipment_export.xlsx";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  downloadCSV: (filter, filters) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/subscriptions/downloadCSV?token=" +
          encodeURI(token) +
          "&backend=" +
          encodeURI(store.state.backend) +
          "_" +
          (filters ? "&filters=" + encodeURI(JSON.stringify(filters)) : "") +
          (filter ? "&filter=" + encodeURI(JSON.stringify(filter)) : "")
      );
      link.setAttribute("target", "_blank");
      let filename =
        "roscioli_wc_" + store.state.backend + "shipment_export.csv";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  downloadEXCEL: (filter, filters) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/subscriptions/downloadEXCEL?token=" +
          encodeURI(token) +
          "&backend=" +
          encodeURI(store.state.backend) +
          "_" +
          (filters ? "&filters=" + encodeURI(JSON.stringify(filters)) : "") +
          (filter ? "&filter=" + encodeURI(JSON.stringify(filter)) : "")
      );
      link.setAttribute("target", "_blank");
      let filename =
        "roscioli_wc_" + store.state.backend + "shipment_export.xlsx";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  downloadOrdersCSV: (filter, filters) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/subscriptions/downloadOrdersCSV?token=" +
          encodeURI(token) +
          "&backend=" +
          encodeURI(store.state.backend) +
          "_" +
          (filters ? "&filters=" + encodeURI(JSON.stringify(filters)) : "") +
          (filter ? "&filter=" + encodeURI(JSON.stringify(filter)) : "")
      );
      link.setAttribute("target", "_blank");
      let filename = "roscioli_wc_" + store.state.backend + "orders_export.csv";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  downloadOrdersEXCEL: (filter, filters) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/subscriptions/downloadOrdersEXCEL?token=" +
          encodeURI(token) +
          "&backend=" +
          encodeURI(store.state.backend) +
          "_" +
          (filters ? "&filters=" + encodeURI(JSON.stringify(filters)) : "") +
          (filter ? "&filter=" + encodeURI(JSON.stringify(filter)) : "")
      );
      link.setAttribute("target", "_blank");
      let filename =
        "roscioli_wc_" + store.state.backend + "orders_export.xlsx";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  deleteExport: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/subscriptions/deleteExport",
        data: { id: id, backend: store.state.backend },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
});

export default subscriptions;
