<template>
  <v-dialog
    persistent
    overlay-opacity="0.6"
    max-width="1024"
    :value="openDialog"
  >
    <messageBox ref="msgBox" />

    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-dropbox</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 20px"
                  >{{
                    editing ? "Modifica transazione" : "Aggiungi transazione"
                  }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <v-card-text>
              <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
                <v-row dense>
                  <v-col class="body-2">
                    Transazione stripe: {{ transaction.transaction_id }}
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      :immediate="editing"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="transaction.name"
                        label="Nome"
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col v-if="transaction.country == 'IT'">
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      :immediate="editing"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="transaction.fiscal_code"
                        label="Codice Fiscale"
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      :immediate="editing"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="transaction.phone"
                        label="Telefono"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <v-text-field
                      outlined
                      dense
                      v-model="transaction.amount"
                      label="Totale"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <ValidationProvider
                      rules="required|email"
                      v-slot="{ errors, valid }"
                      :immediate="editing"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="transaction.email"
                        label="Email"
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      :immediate="editing"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="transaction.address"
                        label="Indirizzo"
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      :immediate="editing"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="transaction.city"
                        label="Città"
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      :immediate="editing"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="transaction.postcode"
                        label="Codice postale"
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col>
                    <v-text-field
                      outlined
                      dense
                      v-model="transaction.state"
                      label="Stato"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      :immediate="editing"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="transaction.country"
                        label="Nazione"
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-text-field
                      v-model="transaction.description"
                      label="Descrizione"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      :immediate="editing"
                    >
                      <v-select
                        v-model="transaction.vat_exemption"
                        label="Esenzione iva"
                        :items="vat_exemptions"
                        outlined
                        dense
                        item-text="value"
                        item-value="id"
                        :error-messages="errors"
                        :success="valid"
                      ></v-select
                    ></ValidationProvider>
                  </v-col>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      :immediate="editing"
                    >
                      <v-select
                        v-model="transaction.vat_exemption_texts"
                        label="Esenzione iva"
                        :items="vat_exemption_texts"
                        outlined
                        dense
                        item-text="value"
                        item-value="id"
                        :error-messages="errors"
                        :success="valid"
                      ></v-select
                    ></ValidationProvider>
                  </v-col>
                </v-row>
                <v-row class="text-center">
                  <v-col>
                    <v-btn
                      :disabled="invalid || !validated"
                      width="120"
                      @click="confirm()"
                      class="ma-2"
                      color="primary"
                      >Conferma</v-btn
                    >
                    <v-btn
                      dark
                      width="120"
                      @click="dismiss()"
                      class="ma-2"
                      color="secondary"
                      >Annulla</v-btn
                    >
                  </v-col>
                </v-row>
              </ValidationObserver>
            </v-card-text>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import messageBox from "../components/MessageBox";

export default {
  data() {
    return {
      transaction: null,
      openDialog: false,
      dialogResolve: null,
      dialogReject: null,
      editing: false,
      vat_exemptions: [
        { id: "N3.1", value: "N3.1" },
        { id: "N3.2", value: "N3.2" },
        { id: "REVC", value: "REVC" }
      ],
      vat_exemption_texts: [
        { id: false, value: "Nessuno" },
        {
          id: "VAT not applicable art. 41 - CO.1 D.L. 331/93",
          value: "VAT not applicable art. 41 - CO.1 D.L. 331/93"
        },
        {
          id: "VAT not applicable art. 8 CO. 1, Lett. A del DPR 633/72",
          value: "VAT not applicable art. 8 CO. 1, Lett. A del DPR 633/72"
        }
      ]
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    messageBox
  },

  methods: {
    show(transaction, eu_codes) {
      return new Promise((resolve, reject) => {
        this.transaction = transaction;
        if (!transaction.vat_exemption_texts) transaction.vat_exemption_texts = false;
        if (!transaction.vat_exemption) {
          transaction.vat_exemption = this.getDefaultExemption(
            transaction,
            eu_codes
          );
        }
        this.editing = true;
        this.dialogResolve = resolve;
        this.dialogReject = reject;
        this.openDialog = true;
      });
    },

    getDefaultExemption(item, eu_codes) {
      if (item.vat_exemption) return item.vat_exemption;
      if (item.country == "IT") return "REVC";
      if (eu_codes.indexOf(item.country) >= 0) {
        return "N3.2";
      } else {
        return "N3.1";
      }
    },

    hide() {
      this.openDialog = false;
      this.dialogReject();
    },

    confirm() {
      this.openDialog = false;
      this.dialogResolve(this.transaction);
    },

    dismiss() {
      this.hide();
    },

    closeDialog() {
      this.hide();
    }
  }
};
</script>
