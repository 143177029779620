import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Bundles from "../views/Bundles.vue";
import Login from "../views/Login.vue";
import SideBar from "../components/Sidebar";
import ClubSelector from "../components/ClubSelector";
import InvoicesStripe from "../views/InvoicesStripe.vue";
import InvoicesArchive from "../views/InvoicesArchive.vue";

const Recovery = () =>
  import(/* webpackChunkName: "user-bundle" */ "../views/Recovery.vue");
const PwdReset = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/Reset.vue");
const Profile = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/Profile.vue");
const BackOfficeUsers = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/UsersManager.vue");
const BackOfficeGroups = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/GroupsManager.vue");
const BackOfficeApplications = () =>
  import(
    /* webpackChunkName: "rare-bundle" */ "../views/ApplicationsManager.vue"
  );
const BackOfficeProducts = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/Products.vue");
const BackOfficePriceLists = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/PriceList.vue");
const BackofficeRegistry = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/Registry.vue");
const BackOfficeWarehouse = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/Warehouse.vue");
const BackOfficeWarehouseOrders = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/WarehouseOrders.vue");
const BackOfficeWarehouseAssignments = () =>
  import(
    /* webpackChunkName: "rare-bundle" */ "../views/WarehouseAssignments.vue"
  );
const BackOfficeExports = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/Exports.vue");
const BackOfficeExportsDetails = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/ExportsDetails.vue");
const BackOfficeStats = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/Stats.vue");
const BackOfficeBundles = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/Bundles.vue");
const BackOfficeShipments = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/Shipments.vue");

const ManualOrders = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/ManualOrders.vue");
const ManualNYOrders = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/ManualNYOrders.vue");

const OneTimeOrders = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/OneTimeOrders.vue");

const FileManager = () =>
  import(/* webpackChunkName: "rare-bundle" */ "../views/FileManager.vue");

import store from "../store";

import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/subscriptions",
    name: "Subscriptions",
    components: {
      header: Header,
      selector: ClubSelector,
      main: Home,
      footer: Footer,
      sideBar: SideBar
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/invoices/stripe",
    name: "InvoicesStripe",
    components: {
      header: Header,
      main: InvoicesStripe,
      footer: Footer,
      sideBar: SideBar
    },
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/invoices/archive",
    name: "InvoicesArchive",
    components: {
      header: Header,
      main: InvoicesArchive,
      footer: Footer,
      sideBar: SideBar
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/filemanager",
    name: "filemanager",
    components: {
      header: Header,
      sideBar: SideBar,
      main: FileManager,
      footer: Footer
    },
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/bundles",
    name: "Bundles",
    components: {
      header: Header,
      selector: ClubSelector,
      main: Bundles,
      footer: Footer,
      sideBar: SideBar
    },
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/Login",
    name: "Login",
    components: {
      main: Login
    }
  },
  {
    path: "/profile",
    name: "Profile",
    components: {
      header: Header,
      main: Profile,
      footer: Footer,
      sideBar: SideBar
    },
    meta: {
      requiresAuth: true,
      requiresAdmin: false
    }
  },
  {
    path: "/recovery",
    name: "recovery",
    components: {
      main: Recovery,
      header: Header,
      footer: Footer
    }
  },
  {
    path: "/reset",
    name: "reset",
    components: {
      header: Header,
      main: PwdReset,
      footer: Footer
    }
  },
  {
    path: "/backoffice/users",
    name: "backoffice-users",
    components: {
      header: Header,
      main: BackOfficeUsers,
      footer: Footer,
      sideBar: SideBar
    },
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },

  {
    path: "/backoffice/groups",
    name: "backoffice-groups",
    components: {
      header: Header,
      main: BackOfficeGroups,
      footer: Footer,
      sideBar: SideBar
    },
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: "/backoffice/applications",
    name: "backoffice-applications",
    components: {
      header: Header,
      main: BackOfficeApplications,
      footer: Footer,
      sideBar: SideBar
    },
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true
    }
  },

  {
    path: "/backoffice/exports",
    name: "backoffice-exports",
    components: {
      header: Header,
      selector: ClubSelector,
      main: BackOfficeExports,
      footer: Footer,
      sideBar: SideBar
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/backoffice/exports-details",
    name: "backoffice-exports-details",
    components: {
      header: Header,
      selector: ClubSelector,
      main: BackOfficeExportsDetails,
      footer: Footer,
      sideBar: SideBar
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/",
    name: "Statistics",
    components: {
      header: Header,
      main: BackOfficeStats,
      footer: Footer,
      sideBar: SideBar
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/backoffice/products",
    name: "backoffice-products",
    components: {
      header: Header,
      main: BackOfficeProducts,
      footer: Footer,
      sideBar: SideBar
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/backoffice/pricelists",
    name: "backoffice-pricelists",
    components: {
      header: Header,
      main: BackOfficePriceLists,
      footer: Footer,
      sideBar: SideBar
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/backoffice/registry",
    name: "backoffice-registry",
    components: {
      header: Header,
      main: BackofficeRegistry,
      footer: Footer,
      sideBar: SideBar
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/backoffice/warehouse",
    name: "backoffice-warehouse",
    components: {
      header: Header,
      main: BackOfficeWarehouse,
      footer: Footer,
      sideBar: SideBar
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/backoffice/warehouse-orders",
    name: "backoffice-warehouse-orders",
    components: {
      header: Header,
      main: BackOfficeWarehouseOrders,
      footer: Footer,
      sideBar: SideBar
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/backoffice/warehouse-assegnations",
    name: "backoffice-warehouse-assegnations",
    components: {
      header: Header,
      main: BackOfficeWarehouseAssignments,
      footer: Footer,
      sideBar: SideBar
    },
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/backoffice/bundles",
    name: "backoffice-bundles",
    components: {
      header: Header,
      main: BackOfficeBundles,
      footer: Footer,
      sideBar: SideBar
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/backoffice/shipments",
    name: "backoffice-shipments",
    components: {
      header: Header,
      selector: ClubSelector,
      main: BackOfficeShipments,
      footer: Footer,
      sideBar: SideBar
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/manual-orders",
    name: "manual-orders",
    components: {
      header: Header,
      selector: ClubSelector,
      main: ManualOrders,
      footer: Footer,
      sideBar: SideBar
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/manual-ny-orders",
    name: "manual-ny-orders",
    components: {
      header: Header,
      selector: ClubSelector,
      main: ManualNYOrders,
      footer: Footer,
      sideBar: SideBar
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/onetime-orders",
    name: "onetime-orders",
    components: {
      header: Header,
      selector: ClubSelector,
      main: OneTimeOrders,
      footer: Footer,
      sideBar: SideBar
    },
    meta: {
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (to.meta.requiresAdmin && !store.state.isAdmin(store.state)) {
      next("/login");
      return;
    }
    if (to.meta.requiresSuperAdmin && !store.state.isSuperAdmin(store.state)) {
      next("/login");
      return;
    }

    if (store.state.user.id > 0) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
