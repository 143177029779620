<template>
  <div>
    <v-alert color="#2A3B4D" dark dense dismissible
      ><v-row dense class="subtitle-1 text-center"
        ><v-col>
          <span v-if="selected_wineclub == 'int'"
            >Stai lavorando sul Wine Club Internazionale. Per passare ad un
            altro Wine Club, selezionalo dall' elenco :</span
          >
          <span v-if="selected_wineclub == 'it'"
            >Stai lavorando sul Wine Club Italiano. Per passare ad un altro Wine
            Club, selezionalo dall' elenco :</span
          >

          <v-select
            v-model="selected_wineclub"
            :items="wineclubs"
            style="max-width:200px; margin: auto;"
            dense
            item-text="name"
            item-value="prefix"
            hide-details
            outlined/></v-col
      ></v-row>
    </v-alert>
  </div>
</template>

<script>
export default {
  data() {
    return {
      wineclubs: [
        {
          name: "Internazionale",
          prefix: "int",
        },
        {
          name: "Italiano",
          prefix: "it",
        },
      ],
      selected_wineclub: this.$store.state.backend,
    };
  },

  watch: {
    selected_wineclub: {
      handler() {
        this.$store.commit("switchWC", this.selected_wineclub);
      },

      immediate: false,
    },
  },
};
</script>
