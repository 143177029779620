import axios from "axios";
import config from "../config";

let users = {
  getEditorialPrefill: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/getEditorialPrefill",
        data: {},
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  setNewPassword: (id, password) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/setNewPassword",
        data: { id: id, password: password },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchGroups: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/groups/list",
        data: { ctx: ctx },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.groups,
            totalCount: resp.data.responseData.count
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchRights: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/fetchRights",
        data: { ctx: ctx },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.rights,
            totalCount: resp.data.responseData.count
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  addGroup: group => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/groups/add",
        data: { group: group },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData.group);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateGroup: group => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/groups/update",
        data: { group: group },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  removeGroup: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/groups/delete",
        data: { id: id },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchAvatar: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/avatar",
        data: { id: id },
        method: "POST"
      })
        .then(resp => {
          resolve({ avatar: resp.data.responseData });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchUsers: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/list",
        data: { ctx: ctx },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.users,
            totalCount: resp.data.responseData.count
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchUsersNames: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/names",
        data: {},
        method: "POST"
      })
        .then(resp => {
          resolve({ items: resp.data.responseData.users });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  banUser: user => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/ban",
        data: { id: user.id, state: !user.banned },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  addUser: user => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/add",
        data: { user: user },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateUser: user => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/update",
        data: { user: user },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateProfile: user => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/updateProfile",
        data: { user: user },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  uploadAvatar: content => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/updateAvatar",
        data: { content: content },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  removeUser: (id, deleteMailBox) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/delete",
        data: { id: id, deleteMailBox: deleteMailBox },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  impersonateUser: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/auth/impersonate",
        data: { id: id },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  checkOpeCode: (code, id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/checkOpeCode",
        data: { code: code, id: id },
        method: "POST"
      })
        .then(count => {
          resolve(count);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  changePassword: (password, newPassword) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/changePassword",
        data: { password: password, newPassword: newPassword },
        method: "POST"
      })
        .then(state => {
          resolve(state);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  recoveryAccount: email => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/recoveryAccount",
        data: { email: email },
        method: "POST"
      })
        .then(state => {
          resolve(state);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  resetPassword: (password, token) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/resetPassword",
        data: { password: password, token: token },
        method: "POST"
      })
        .then(state => {
          resolve(state);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  keepAlive: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/keepAlive",
        data: {},
        method: "POST"
      })
        .then(state => {
          resolve(state);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default users;
