import Axios from "axios";

import router from "../router";
import localStorageService from "./localStorageService";
import config from "../config";
import store from "../store";
const packageInfo = require("../../package.json");

const AxiosInterceptors = (function() {
  function _setupInterceptors() {
    Axios.interceptors.request.use(
      (config) => {
        const token = localStorageService.getAccessToken();
        if (token) {
          config.headers["authorization"] = "Bearer " + token;
        }
        config.headers["vue-app-version"] = packageInfo.version;
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    Axios.interceptors.response.use(
      function(response) {
        if (response.status == 205) {
          localStorageService.clearToken();
          localStorageService.clearData();
          location.reload(true);
          return Promise.reject();
        }
        return response;
      },
      function(error) {
        if (error.config && error.response) {
          const originalRequest = error.config;
          // If this is failed refresh token request, logout
          if (
            error.response.status === 401 &&
            originalRequest.url === config.apiEndPoint + "/auth/logout"
          ) {
            localStorageService.clearToken();
            router.push("/login");
            return Promise.reject(error);
          }
          // If this a failed request, try to refresh the access token, we also get back the user notifications status here, as well as within the login phase
          const refreshToken = localStorageService.getRefreshToken();
          if (
            refreshToken &&
            error.response.status == 401 &&
            !originalRequest.headers["auth-retry-state"] &&
            originalRequest.url != config.apiEndPoint + "/auth/refresh"
          ) {
            return Axios.post(config.apiEndPoint + "/auth/refresh", {
              refresh_token: refreshToken,
            })
              .then((res) => {
                if (res.status === 200) {
                  if (res.data.responseData) {
                    if (
                      res.data.responseData.authToken &&
                      res.data.responseData.refreshToken
                    ) {
                      localStorageService.setToken({
                        access_token: res.data.responseData.authToken,
                        refresh_token: res.data.responseData.refreshToken,
                      });
                    }
                    if (res.data.responseData.notifications) {
                      store.state.user.notifications =
                        res.data.responseData.notifications;
                      localStorageService.setNotifications(
                        store.state.user.notifications
                      );
                    }
                  }
                  originalRequest.headers["authorization"] =
                    "Bearer " + localStorageService.getAccessToken();
                  originalRequest.headers["auth-retry-state"] = true;
                  return Axios(originalRequest);
                } else {
                  // Refreshing token failed
                  localStorageService.clearToken();
                  router.push("/login");
                  return Promise.reject(error);
                }
              })
              .catch((error) => {
                localStorageService.clearToken();
                router.push("/login");
                return Promise.reject(error);
              });
          }
        }

        return Promise.reject(error);
      }
    );
  }
  return {
    setupInterceptors: _setupInterceptors,
  };
})();

export default AxiosInterceptors;
