<template>
  <v-dialog
    persistent
    overlay-opacity="0.6"
    max-width="800"
    :value="openDialog"
  >
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1"
                  ><v-icon large>mdi-comment-question-outline</v-icon></v-col
                >
                <v-col align="center" cols="10" style="font-size: 20px">{{
                  title
                }}</v-col>
                <v-col cols="1">
                  <v-btn icon>
                    <v-icon @click="closeDialog" large>mdi-close</v-icon>
                  </v-btn></v-col
                >
              </v-row>
            </template>

            <v-card-text>
              <span style="font-size:20px;">{{ text }}</span>
              <v-row class="text-center">
                <v-col>
                  <v-btn
                    dark
                    width="120"
                    @click="confirm()"
                    class="ma-2"
                    color="primary"
                    >Conferma</v-btn
                  >
                  <v-btn
                    dark
                    width="120"
                    @click="dismiss()"
                    class="ma-2"
                    v-if="!okonly"
                    color="secondary"
                    >Annulla</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: "",
      text: "",
      okonly: false,
      openDialog: false,
      dialogResolve: null,
      dialogReject: null,
    };
  },

  methods: {
    show(text, title, okonly = false) {
      return new Promise((resolve, reject) => {
        this.value = true;
        this.title = title;
        this.text = text;
        this.okonly = okonly;
        this.dialogResolve = resolve;
        this.dialogReject = reject;
        this.openDialog = true;
      });
    },

    hide() {
      this.openDialog = false;
      this.dialogReject();
    },

    confirm() {
      this.openDialog = false;
      this.dialogResolve();
    },

    dismiss() {
      this.hide();
    },

    closeDialog() {
      this.hide();
    },
  },
};
</script>
