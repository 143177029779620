<template>
  <v-dialog
    persistent
    overlay-opacity="0.6"
    max-width="800"
    :value="openDialog"
  >
    <messageBox ref="msgBox" />
    <setNumber ref="setNumber" />
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1"><v-icon large>mdi-dropbox</v-icon></v-col>
                <v-col
                  align="center"
                  cols="10"
                  class="card-title-text"
                  style="font-size: 20px"
                  >{{
                    editing ? "Modifica pacchetto" : "Aggiungi pacchetto"
                  }}</v-col
                >
                <v-col cols="1">
                  <v-btn icon>
                    <v-icon @click="closeDialog" large>mdi-close</v-icon>
                  </v-btn></v-col
                >
              </v-row>
            </template>
            <v-card-text>
              <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
                <v-row>
                  <v-col>
                    <v-card elevation="2" outlined>
                      <v-card-title>
                        Proprietà pacchetto
                      </v-card-title>
                      <v-card-text>
                        <v-form class="mt-5" ref="user-editor-form">
                          <ValidationProvider
                            :immediate="true"
                            rules="required"
                            v-slot="{ errors, valid }"
                          >
                            <v-text-field
                              v-model="bundle.source_name"
                              label="Nome"
                              prepend-icon="mdi-dropbox"
                              :error-messages="errors"
                              :success="valid"
                              required
                            ></v-text-field>
                          </ValidationProvider>
                          <ValidationProvider
                            :immediate="true"
                            rules="required"
                            v-slot="{ errors, valid }"
                          >
                            <v-text-field
                              v-model="bundle.description"
                              label="Descrizione"
                              prepend-icon="mdi-format-align-justify"
                              :error-messages="errors"
                              :success="valid"
                              required
                              :immediate="true"
                            ></v-text-field>
                          </ValidationProvider>
                          <ValidationProvider
                            :immediate="true"
                            rules="required|min_value:1"
                            v-slot="{ errors, valid }"
                          >
                            <v-text-field
                              v-model="bundle.required_payments"
                              label="Pagamenti richiesti"
                              prepend-icon="mdi-currency-eur"
                              :error-messages="errors"
                              :success="valid"
                              required
                              :immediate="true"
                            ></v-text-field>
                          </ValidationProvider>
                          <v-dialog
                            ref="dialog_start_date"
                            v-model="date_start_modal"
                            :return-value.sync="date_start"
                            width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <ValidationProvider
                                rules="required"
                                v-slot="{ errors, valid }"
                                :immediate="true"
                              >
                                <v-text-field
                                  v-model="start_date_formatted"
                                  label="Data di inizio"
                                  prepend-icon="mdi-calendar-multiple"
                                  :error-messages="errors"
                                  :success="valid"
                                  required
                                  v-on="on"
                                  clearable
                                  readonly
                                  :immediate="true"
                                ></v-text-field>
                              </ValidationProvider>
                            </template>
                            <v-date-picker
                              @input="$refs.dialog_start_date.save(date_start)"
                              v-model="date_start"
                              scrollable
                            >
                            </v-date-picker>
                          </v-dialog>

                          <v-dialog
                            ref="dialog_end_date"
                            v-model="date_end_modal"
                            :return-value.sync="date_end"
                            width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <ValidationProvider
                                rules="required"
                                v-slot="{ errors, valid }"
                                :immediate="true"
                              >
                                <v-text-field
                                  v-model="end_date_formatted"
                                  readonly
                                  clearable
                                  v-on="on"
                                  label="Data di fine"
                                  prepend-icon="mdi-calendar-multiple"
                                  :error-messages="errors"
                                  :success="valid"
                                  :immediate="true"
                                ></v-text-field>
                              </ValidationProvider>
                            </template>
                            <v-date-picker
                              @input="$refs.dialog_end_date.save(date_end)"
                              v-model="date_end"
                              scrollable
                            >
                            </v-date-picker>
                          </v-dialog>
                        </v-form>
                      </v-card-text>
                    </v-card> </v-col
                ></v-row>
                <v-row>
                  <v-col>
                    <v-card elevation="2" outlined>
                      <v-card-title style="padding-bottom:0px">
                        Composizione prodotti ({{ products.length }})
                      </v-card-title>
                      <v-card-text>
                        <v-data-table
                          class="grid-table"
                          :must-sort="true"
                          :headers="headers"
                          :items="products"
                          :server-items-length="products.length"
                          :loading="loadingProducts"
                        >
                          <template v-slot:item.qty="{ item }">
                            {{ item.qty
                            }}<v-tooltip bottom
                              ><template v-slot:activator="{ on }"
                                ><v-btn
                                  x-small
                                  text
                                  icon
                                  color="secondary"
                                  @click.stop="setQuantity(item)"
                                  v-on="on"
                                  ><v-icon>mdi-pencil</v-icon></v-btn
                                ></template
                              ><span>{{ "Imposta quantità" }}</span></v-tooltip
                            >
                          </template>
                          <template v-slot:item.value="{ item }">
                            {{ item.value
                            }}<v-tooltip bottom
                              ><template v-slot:activator="{ on }"
                                ><v-btn
                                  x-small
                                  text
                                  icon
                                  color="secondary"
                                  @click.stop="setValue(item)"
                                  v-on="on"
                                  ><v-icon>mdi-pencil</v-icon></v-btn
                                ></template
                              ><span>{{ "Imposta valore" }}</span></v-tooltip
                            >
                          </template>

                          <template v-slot:item.actions="{ item }">
                            <v-tooltip bottom
                              ><template v-slot:activator="{ on }"
                                ><v-btn
                                  x-small
                                  text
                                  icon
                                  color="error"
                                  @click.stop="removeProduct(item)"
                                  v-on="on"
                                  ><v-icon>mdi-delete</v-icon></v-btn
                                ></template
                              ><span>{{ "Cancella prodotto" }}</span></v-tooltip
                            >
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>

                    <v-row>
                      <v-col>
                        <v-card elevation="2" outlined>
                          <v-card-title style="padding-bottom:0px">
                            Aggiungi prodotto
                          </v-card-title>
                          <v-card-text>
                            <v-row>
                              <v-col>
                                <v-autocomplete
                                  v-model="missingProduct"
                                  :items="missingProducts"
                                  :loading="loadingMissingProducts"
                                  :search-input.sync="missingProductSearch"
                                  item-text="name"
                                  item-value="id"
                                  prepend-icon="mdi-bottle-wine"
                                  append-icon=""
                                  placeholder="Scrivi per ricercare"
                                  clearable
                                  return-object
                                >
                                  <template v-slot:append-outer>
                                    <v-btn
                                      class="text-center pa-0 ma-0"
                                      :disabled="!missingProduct"
                                      icon
                                      dark
                                      color="success"
                                      @click.stop="addProductToBundle()"
                                      ><v-icon>mdi-plus</v-icon></v-btn
                                    >
                                  </template>
                                </v-autocomplete>
                              </v-col></v-row
                            >
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="text-center">
                  <v-col>
                    <v-btn
                      :disabled="invalid || !validated"
                      width="120"
                      style="color:white;"
                      @click="confirm()"
                      class="ma-2"
                      color="primary"
                      >Conferma</v-btn
                    >
                    <v-btn
                      dark
                      width="120"
                      @click="dismiss()"
                      class="ma-2"
                      color="secondary"
                      >Annulla</v-btn
                    >
                  </v-col>
                </v-row>
              </ValidationObserver>
            </v-card-text>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import productsManager from "../apis/products";
import messageBox from "../components/MessageBox";
import setNumber from "../components/SetNumber.vue";

export default {
  data() {
    return {
      bundle: null,
      openDialog: false,
      dialogResolve: null,
      dialogReject: null,
      editing: false,
      date_start_modal: false,
      date_end_modal: false,
      date_start: null,
      date_end: null,
      loadingMissingProducts: false,
      missingProduct: null,
      missingProductSearch: null,
      missingProducts: [],
      products: []
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    messageBox,
    setNumber
  },

  mounted() {
    this.headers = [
      {
        text: "Nome",
        align: "left",
        sortable: true,
        value: "name"
      },
      {
        text: "Quantità",
        align: "left",
        sortable: true,
        value: "qty"
      },
      {
        text: "Provenienza",
        align: "left",
        sortable: true,
        value: "structure"
      },
      {
        text: "Valore di rivendita",
        align: "left",
        sortable: true,
        value: "value"
      },

      { text: "", align: "center", sortable: false, value: "actions" }
    ];
  },

  computed: {
    start_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(this.date_start);
      },
      set: function(v) {
        this.date_start = v;
      }
    },

    end_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(this.date_end);
      },
      set: function(v) {
        this.date_end = v;
      }
    }
  },

  watch: {
    missingProductSearch(value) {
      this.missingProducts = [];
      if (!value) return;
      clearTimeout(this._searchDebounceTimer);
      this._searchDebounceTimer = setTimeout(() => {
        this.fetchMissingProducts(value);
      }, 300);
    }
  },

  methods: {
    show(bundle, editing) {
      return new Promise((resolve, reject) => {
        this.bundle = bundle;
        this.date_start = this.bundle.start_date.substr(0, 10);
        this.date_end = this.bundle.end_date.substr(0, 10);

        this.editing = editing;
        this.dialogResolve = resolve;
        this.dialogReject = reject;
        this.openDialog = true;

        this.loadingProducts = true;
        this.fetchProducts()
          .then(res => {
            this.products = res.items;
            this.loadingProducts = false;
          })
          .catch(err => {
            console.log(err);
            this.loadingProducts = false;
          });
      });
    },

    hide() {
      this.openDialog = false;
      this.dialogReject();
    },

    confirm() {
      this.openDialog = false;
      this.bundle.start_date = new Date(
        Date.parse(this.date_start + "T12:00:00.000Z")
      );
      this.bundle.start_date = this.bundle.start_date.getTime();
      this.bundle.end_date = new Date(
        Date.parse(this.date_end + "T12:00:00.000Z")
      );
      this.bundle.end_date = this.bundle.end_date.getTime();
      this.dialogResolve(this.bundle);
    },

    dismiss() {
      this.hide();
    },

    closeDialog() {
      this.hide();
    },

    addProductToBundle() {
      let id = this.missingProduct.id;
      this.missingProduct = null;
      productsManager
        .addBundleProduct(this.bundle.id, id)
        .then(() => {
          this.fetchProducts()
            .then(res => {
              this.products = res.items;
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    setQuantity(product) {
      let id = product.product_id;
      this.$refs.setNumber
        .show(
          "Imposta quantità",
          "Quantità",
          "Quantità prodotto nel pacchetto",
          product.qty,
          null,
          true,
          false
        )
        .then(event => {
          productsManager
            .setBundleProductQty(this.bundle.id, id, event.value)
            .then(() => {
              this.fetchProducts()
                .then(res => {
                  this.products = res.items;
                })
                .catch(err => {
                  console.log(err);
                });
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    setValue(product) {
      let id = product.product_id;
      this.$refs.setNumber
        .show(
          "Imposta valore",
          "Valore",
          "Valore prodotto nel pacchetto",
          product.value,
          null,
          true,
          false
        )
        .then(event => {
          productsManager
            .setBundleProductValue(this.bundle.id, id, event.value)
            .then(() => {
              this.fetchProducts()
                .then(res => {
                  this.products = res.items;
                })
                .catch(err => {
                  console.log(err);
                });
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    removeProduct(product) {
      this.$refs.msgBox
        .show(
          "Vuoi davvero confermare la cancellazione del prodotto ?",
          "Conferma operazione"
        )
        .then(() => {
          productsManager
            .removeBundleProduct(this.bundle.id, product.product_id)
            .then(() => {
              const index = this.product.indexOf(product);
              if (index > -1) {
                this.products.splice(index, 1);
              }
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    fetchProducts() {
      this.loadingProducts = true;
      return new Promise((resolve, reject) => {
        productsManager
          .fetchBundleProducts(this.bundle.id)
          .then(products => {
            this.loadingProducts = false;
            resolve(products);
          })
          .catch(err => {
            reject(err);
            this.loadingProducts = false;
          });
      });
    },

    fetchMissingProducts(value) {
      this.loadingMissingProducts = true;
      return new Promise((resolve, reject) => {
        this.loadingMissingProducts = false;
        productsManager
          .searchBundleProduct(this.bundle.id, value)
          .then(result => {
            this.missingProducts = result.items;
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    }
  }
};
</script>
