<template>
  <div>
    <messageBox ref="msgBox" />
    <invoiceDate ref="invoiceDate" />
    <SetNumber ref="setNumber" />
    <SetString ref="setNotes" />
    <TransactionEditor ref="transactionEditor" />

    <base-material-card class="pa-0" color="primary">
      <template v-slot:heading>
        <v-row class="pa-0">
          <v-col cols="1" class="pa-0"><v-icon large>mdi-lock</v-icon></v-col>
          <v-col
            align="center"
            cols="11"
            class="pa-0 card-title-text"
            style="font-size: 20px"
            >Fatturazione transazioni STRIPE</v-col
          >
        </v-row>
      </template>
      <v-card-text>
        <div style="margin:15px">
          <v-row v-if="!fetchingFilters">
            <v-col>
              <v-text-field
                outlined
                v-model="filter"
                append-icon="mdi-magnify"
                class="mr-auto ml-1 pl-1"
                label="Ricerca"
                hide-details
                dense
              />
            </v-col>

            <v-col>
              <v-select
                dense
                v-model="filters.type"
                outlined
                item-text="name"
                item-value="id"
                :items="invoices_types"
                label="Wine Club"
                hide-details
            /></v-col>
            <v-col>
              <v-select
                dense
                v-model="filters.charge_type"
                outlined
                item-text="name"
                item-value="id"
                :items="charge_type"
                label="Tipologia"
                hide-details
            /></v-col>
            <v-col>
              <v-select
                class="minfield"
                outlined
                dense
                label="Periodo"
                ref="period"
                v-model="filters.filterByPeriod"
                :items="getPeriodFilters"
                item-text="name"
                item-value="id"
              >
              </v-select>
            </v-col>
            <v-col v-if="filters.filterByPeriod == 13">
              <v-dialog
                ref="dialog_start_date"
                v-model="date_start_modal"
                :return-value.sync="filters.filterByCustomDateStart"
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    dense
                    outlined
                    v-model="start_date_formatted"
                    label="Data iniziale"
                    readonly
                    clearable
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="
                    $refs.dialog_start_date.save(
                      filters.filterByCustomDateStart
                    )
                  "
                  v-model="filters.filterByCustomDateStart"
                  scrollable
                  :first-day-of-week="1"
                  :locale="$store.state.user.locale"
                >
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col v-if="filters.filterByPeriod == 13">
              <v-dialog
                ref="dialog_end_date"
                v-model="date_end_modal"
                :return-value.sync="filters.filterByCustomDateEnd"
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    dense
                    outlined
                    v-model="end_date_formatted"
                    label="Data finale"
                    readonly
                    clearable
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="
                    $refs.dialog_end_date.save(filters.filterByCustomDateEnd)
                  "
                  v-model="filters.filterByCustomDateEnd"
                  scrollable
                  :first-day-of-week="1"
                  :locale="$store.state.user.locale"
                >
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </div>
        <v-row dense>
          <v-col>
            <v-select
              v-model="selectionMode"
              :items="selectionModes"
              dense
              item-text="value"
              item-value="id"
              outlined
              style="max-width:200px;margin:auto"
              label="Modalità selezione"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-btn
              class="ma-2"
              v-if="!fetchingFilters && $store.state.hasRight('248')"
              @click="buildInvoices()"
              :disabled="selected_invoices.length < 1 || this.selectionMode"
              >Genera fatture</v-btn
            >
            <v-btn
              class="ma-2"
              v-if="!fetchingFilters && $store.state.hasRight('249')"
              @click="archiveSelection()"
              :disabled="selected_invoices.length < 1"
              >Archivia selezione</v-btn
            >
          </v-col>
        </v-row>

        <v-data-table
          v-if="!fetchingFilters"
          :items-per-page="perPage"
          :must-sort="true"
          :headers="headers"
          :items="invoices"
          :disabled="loading"
          :options.sync="options"
          :server-items-length="totalInvoices"
          :loading="loading"
          :footer-props="dataTableFooterOptions"
          item-key="transaction_id"
          v-model="selected_invoices"
          show-select
          selectable-key="isSelectable"
          :item-class="itemRowClass"
        >
          <template v-slot:item.vat_exemption="{ item }">
            {{ getDefaultExemption(item) }}
          </template>

          <template v-slot:item.name="{ item }">
            {{ item.name }}
          </template>

          <template v-slot:item.address="{ item }">
            {{ item.address }} {{ item.postcode }}
          </template>

          <template v-slot:item.email="{ item }">
            {{
              item.email
                ? item.email
                : item.email_billing
                ? item.email_billing
                : item.email_shipping
                ? item.email_shipping
                : ""
            }}
          </template>

          <template v-slot:item.event_date="{ item }">
            {{ item.event_date | toLocaleDateTime }}
          </template>

          <template v-slot:item.generation_id="{ item }">
            <v-tooltip v-if="itemErrorForInvoice(item)" bottom
              ><template v-slot:activator="{ on }"
                ><v-icon color="red" v-on="on"
                  >mdi-alert-rhombus-outline</v-icon
                > </template
              ><span>{{ itemErrorForInvoice(item) }}</span></v-tooltip
            >
            <v-tooltip v-if="item.doc_number" bottom
              ><template v-slot:activator="{ on }"
                ><v-icon color="green" v-on="on"
                  >mdi-alert-rhombus-outline</v-icon
                >{{ item.doc_number }}/{{ item.doc_year }} </template
              ><span
                >Fattura N. {{ item.doc_number }}/{{ item.doc_year }}</span
              ></v-tooltip
            >
          </template>

          <template v-slot:item.actions="{ item }">
            <div style="min-width:64px">
              <v-tooltip
                bottom
                v-if="!item.generation_id && $store.state.hasRight('250')"
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="secondary"
                    @click="updateTransactionData(item)"
                    v-on="on"
                    ><v-icon>mdi-pencil</v-icon></v-btn
                  ></template
                ><span>Modifica dati</span></v-tooltip
              >

              <v-tooltip
                bottom
                v-if="!item.generation_id && $store.state.hasRight('249')"
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="secondary"
                    @click="archiveTransaction(item)"
                    v-on="on"
                    ><v-icon>mdi-archive</v-icon></v-btn
                  ></template
                ><span>Archivia transazione</span></v-tooltip
              >

              <v-tooltip
                bottom
                v-if="
                  !item.generation_id &&
                    item.isSelectable &&
                    $store.state.hasRight('248')
                "
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="cyan"
                    @click="createInvoice(item)"
                    v-on="on"
                    ><v-icon>mdi-layers-outline</v-icon></v-btn
                  ></template
                ><span>Genera fattura</span></v-tooltip
              >

              <v-tooltip bottom v-if="item.generation_id"
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="secondary"
                    @click="downloadInvoice(item)"
                    v-on="on"
                    ><v-icon>mdi-file-table-outline</v-icon></v-btn
                  ></template
                ><span>Download fattura</span></v-tooltip
              >
              <v-tooltip bottom v-if="item.generation_id"
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="secondary"
                    @click="downloadXML(item)"
                    v-on="on"
                    ><v-icon>mdi-xml</v-icon></v-btn
                  ></template
                ><span>Download XML</span></v-tooltip
              >
            </div>
          </template>
        </v-data-table>
      </v-card-text>
      <div style="height:50px" />
    </base-material-card>
  </div>
</template>

<script>
import staticData from "../apis/staticData";
import invoicesManager from "../apis/invoices";
import messageBox from "../components/MessageBox";
import invoiceDate from "../components/InvoiceDate";
import SetString from "../components/SetString.vue";
import SetNumber from "../components/SetNumber.vue";
import TransactionEditor from "../components/TransactionEditor.vue";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      selectionMode: false,
      selectionModes: [
        {
          id: false,
          value: "Fatturabili"
        },
        {
          id: true,
          value: "Tutti"
        }
      ],
      invoices: [],
      selected_invoices: [],
      date_start_modal: false,
      date_end_modal: false,

      loading: false,
      totalInvoices: 0,
      perPage: staticData.defaultPerPage,
      options: {
        sortBy: ["event_date"],
        sortDesc: [true],
        itemsPerPage: staticData.defaultPerPage
      },

      next_invoice_number: 0,

      headers: [],
      filter: null,
      filters: {
        type: null,
        charge_type: null,
        filterByPeriod: 5,
        filterByCustomDateEnd: null,
        filterByCustomDateStart: null,
        invoices_state: 1
      },

      fetchingFilters: false,

      invoices_types: [
        { id: null, name: "Tutti" },
        { id: 1, name: "Italian Wine Club" },
        { id: 2, name: "Wine Club Italia" },
        { id: 0, name: "Altri" }
      ],

      charge_type: [
        { id: null, name: "Tutti" },
        { id: 0, name: "Accredito" },
        { id: 1, name: "Rimborso" }
      ],

      eu_codes: []
    };
  },

  computed: {
    start_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      }
    },

    end_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      }
    },

    getPeriodFilters: {
      get: function() {
        return staticData.filtersPeriods();
      }
    }
  },

  components: {
    messageBox,
    SetString,
    SetNumber,
    TransactionEditor,
    invoiceDate
  },

  mounted() {
    this.headers = [
      {
        text: "Stripe ID",
        align: "left",
        sortable: true,
        value: "transaction_id"
      },
      {
        text: "Generata",
        align: "center",
        sortable: true,
        value: "generation_id"
      },
      {
        text: "Esenzione IVA",
        align: "center",
        sortable: true,
        value: "vat_exemption"
      },
      { text: "Data", align: "left", sortable: true, value: "event_date" },
      {
        text: "Nome",
        align: "left",
        sortable: true,
        value: "name"
      },
      {
        text: "Email",
        align: "left",
        sortable: true,
        value: "email"
      },

      {
        text: "Descrizione",
        align: "left",
        sortable: true,
        value: "description"
      },
      {
        text: "Città",
        align: "left",
        sortable: true,
        value: "city"
      },

      {
        text: "Indirizzo",
        align: "left",
        sortable: true,
        value: "address"
      },
      {
        text: "CAP",
        align: "left",
        sortable: true,
        value: "postcode"
      },
      {
        text: "Stato",
        align: "left",
        sortable: true,
        value: "state"
      },
      {
        text: "Nazione",
        align: "left",
        sortable: true,
        value: "country"
      },
      {
        text: "Tel",
        align: "left",
        sortable: true,
        value: "phone"
      },

      { text: "Totale", align: "left", sortable: true, value: "amount" },

      { text: "Rif. ordine", align: "left", sortable: true, value: "order_id" },
      { text: "", align: "left", sortable: false, value: "actions" }
    ];

    this.fetchNextInvoice();
  },

  watch: {
    selectionMode: {
      handler() {
        this.fetchInvoices();
      }
    },

    options: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.fetchInvoices();
        }, 300);
      },
      deep: true
    },

    filters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.fetchInvoices();
        }, 300);
      },

      deep: true,
      immediate: true
    },

    filter: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.fetchInvoices();
        }, 300);
      },

      deep: true,
      immediate: true
    }
  },

  methods: {
    getDefaultExemption(item) {
      if (item.vat_exemption) return item.vat_exemption;
      if (item.country == "IT") return "REVC";
      if (this.eu_codes.indexOf(item.country) >= 0) {
        return "N3.2";
      } else {
        return "N3.1";
      }
    },

    itemRowClass(item) {
      if (item.nc) return "invoices-nc";
    },

    downloadInvoice(item) {
      invoicesManager
        .downloadInvoice(item.doc_number, item.doc_year)
        .then(() => {})
        .catch(err => {
          console.log(err);
        });
    },

    downloadXML(item) {
      invoicesManager
        .downloadInvoiceXML(item.doc_number, item.doc_year)
        .then(() => {})
        .catch(err => {
          console.log(err);
        });
    },

    updateTransactionData(item) {
      this.$refs.transactionEditor
        .show(item, this.eu_codes)
        .then(item => {
          invoicesManager.updateData(item).then(() => {
            this.fetchInvoices();
          });
        })
        .catch(() => {})
        .catch(() => {});
    },

    itemErrorForInvoice(item) {
      if (item.country == "IT") {
        if (!item.fiscal_code) return "Codice fiscale mancante";
        if (!item.postcode || item.postcode.length != 5)
          return "CAP mancante o errato";
      }

      if (!item.amount) return "Valore mancante";
      if (!item.address) return "Indirizzo mancante";
      if (!item.phone) return "Telefono mancante";
      if (!item.name) return "Nome mancante";
      if (!item.state) return "Stato mancante";
      if (!item.country) return "Nazione mancante";
      if (!item.city) return "Città mancante";
      if (!item.description) return "Descrizione mancante";
      if (!item.email) return "Email mancante";
      if (!item.postcode) return "Cap mancante";
      return null;
    },

    reserveNumbering() {
      this.$refs.setNumber
        .show(
          "Acquisisci numerazioni",
          "Imposta il numero di fatture da riservare",
          "Totale fatture",
          1,
          null,
          true,
          false
        )
        .then(event => {
          invoicesManager
            .reserveNumbering(event.value)
            .then(() => {
              this.fetchNextInvoice();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    resetNumbering() {
      this.$refs.setNumber
        .show(
          "Reimposta numerazione di partenza",
          "Imposta la numerazione per le fatture",
          "Ultimo numero emesso",
          this.next_invoice_number,
          null,
          true,
          false
        )
        .then(event => {
          invoicesManager
            .setNextValue(event.value)
            .then(() => {
              this.fetchNextInvoice();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    archiveSelection() {
      this.$refs.msgBox
        .show("Vuoi davvero archiviare le transazioni ?", "Conferma operazione")
        .then(() => {
          invoicesManager
            .archiveTransaction(
              this.selected_invoices.map(x => {
                return x.id;
              })
            )
            .then(() => {
              this.fetchInvoices();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    archiveTransaction(item) {
      this.$refs.msgBox
        .show("Vuoi davvero archiviare la transazione ?", "Conferma operazione")
        .then(() => {
          invoicesManager
            .archiveTransaction([item.id])
            .then(() => {
              this.fetchInvoices();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    createInvoice(item) {
      this.$refs.invoiceDate
        .show(item.event_date)
        .then(date => {
          this.$store.state.global_overlay = true;
          this.$store.state.global_overlay_text = this.$t(
            "invoicesmng-building-invoices"
          );
          invoicesManager
            .generateInvoices([item.transaction_id], date)
            .then(() => {
              this.$store.state.global_overlay = false;
              this.fetchInvoices();
            })
            .catch(err => {
              this.$store.state.global_overlay = false;
              console.log(err);
            });
        })
        .catch(() => {});
    },

    buildInvoices() {
      this.$refs.invoiceDate
        .show(this.selected_invoices[0].event_date)
        .then(date => {
          this.$store.state.global_overlay = true;
          this.$store.state.global_overlay_text = this.$t(
            "invoicesmng-building-invoices"
          );
          let transactions_ids = this.selected_invoices.map(x => {
            return x.transaction_id;
          });
          invoicesManager
            .generateInvoices(transactions_ids, date)
            .then(() => {
              this.$store.state.global_overlay = false;

              this.fetchInvoices();
            })
            .catch(err => {
              this.$store.state.global_overlay = false;

              console.log(err);
            });
        })
        .catch(() => {});
    },

    fetchNextInvoice() {
      invoicesManager
        .fetchNextInvoice()
        .then(res => {
          this.next_invoice_number = res;
        })
        .catch(err => {
          console.log(err);
        });
    },

    fetchInvoices() {
      this.loading = true;
      this.invoices = [];
      this.selected_invoices = [];
      this.totalInvoices = 0;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        invoicesManager
          .fetchEuCodes()
          .then(codes => {
            this.eu_codes = [];
            for (let x of codes) {
              this.eu_codes.push(x.code);
            }

            invoicesManager
              .fetchTransactions(ctx)
              .then(result => {
                this.loading = false;
                if (!this.selectionMode) {
                  this.invoices = result.transactions.map(x => {
                    if (x.generation_id || this.itemErrorForInvoice(x))
                      x.isSelectable = false;
                    else x.isSelectable = true;
                    if (x.amount < 0) {
                      x.nc = true;
                      x.isSelectable = false;
                    } else {
                      x.nc = false;
                    }
                    return x;
                  });
                } else {
                  this.invoices = result.transactions;
                }
                this.totalInvoices = result.count;
                resolve(result);
              })
              .catch(err => {
                this.loading = false;
                reject(err);
              });
          })
          .catch(err => {
            this.loading = false;
            reject(err);
          });
      });
    }
  }
};
</script>

<style>
.invoices-nc {
  color: white;
  background-color: #ec4444 !Important;
}
</style>
