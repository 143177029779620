<template>
  <v-app>
    <router-view name="header" v-model="expandOnHover" />
    <router-view name="sideBar" :expand-on-hover.sync="expandOnHover" />
    <v-main>
      <v-overlay :value="$store.state.global_overlay">
        <v-row
          ><v-col
            ><v-alert border="top" colored-border type="info" elevation="2"
              >{{ $store.state.global_overlay_text }}&nbsp;<v-progress-circular
                :indeterminate="true"
                :rotate="0"
                :size="32"
                :width="4"
                color="primary"
              ></v-progress-circular></v-alert></v-col
        ></v-row>
      </v-overlay>
      <router-view name="selector" />
      <v-fade-transition mode="out-in">
        <router-view name="main" />
      </v-fade-transition>
    </v-main>
    <router-view name="footer" />
  </v-app>
</template>

<script>
export default {
  name: "app",
  data: () => ({
    expandOnHover: true,
  }),
};
</script>

<style lang="scss">
@import "sass/variables.scss";
@import "assets/app.scss";
@import "assets/app.css";
</style>

<style>
.profile-gravatar {
  width: 48px;
  height: 48px;
}

.profile-container {
  display: flex;
  align-items: center;
  text-align: left;
  padding: 0px;
  margin: 2px;
}

.profile-circle {
  text-align: left;
  background: #39a7eb;
  border: 0.5px solid gray;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: inline-block;
  text-align: left;
}

.profile-text {
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
}

.profile-name {
  display: inline-block;
  align-items: center;
  margin-left: 5px;
}
</style>
