<template>
  <v-app-bar dark app :src="background_image">
    <template v-slot:img="{ props }">
      <v-img
        v-bind="props"
        gradient="to top right, rgba(10,10,10,.5), rgba(90,90,90,.8)"
      ></v-img>
    </template>

    <v-snackbar
      :timeout="notification_timeout"
      v-model="notification_snackbar"
      :color="notification_severity"
      top
    >
      <v-row>
        <v-col cols="1">
          <v-icon
            v-if="!notification || !notification.avatar_id"
            class="mr-2"
            color="white"
            >mdi-bell</v-icon
          >
          <div v-if="notification && notification.avatar_id">
            <div class="profile-container mr-2">
              <div
                v-if="notification.gravatar"
                class="profile-gravatar"
                v-html="buildGravatar(notification)"
              ></div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-snackbar>
  

    <span class="rimessa-logo-span"
      > <router-link to="/"><img class="rimessa-logo" src="../assets/rimessa_logo_rosso.png"
    /></router-link></span>

    <span class="header-copyright">{{
      $t("wcs-copy", { version: packageInfo.version })
    }}</span>

    <v-spacer />

    <div class="mx-3" />
    <span class="header-welcome">
      {{ $t("wcs-welcome", { name: $store.state.user.first_name }) }}
    </span>

    <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list :tile="false" flat nav>
        <template v-for="(p, i) in profile">
          <v-divider v-if="p.divider" :key="`divider-${i}`" class="mb-2 mt-2" />
          <app-bar-item v-else :key="`item-${i}`">
            <v-list-item-title v-text="p.title" @click="handleClick(p)" />
          </app-bar-item>
        </template>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<style>
.appbar {
  .v-toolbar__content {
    height: 120px;
  }
}
</style>

<style scoped>
.rimessa-logo-span {
  margin-right: 10px;
}

.rimessa-logo {
  max-height: 50px;
  margin-left: 5px;
  margin-right: 5px;
}

.forno-logo {
  max-height: 50px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
}

.header-copyright {
  text-decoration: none;
  text-transform: uppercase;
}

.header-welcome {
  text-decoration: none;
  text-transform: uppercase;
}

@media screen and (max-width: 800px) {
  .v-app-bar {
    font-size: 12px;
  }

  .forno-logo {
    max-height: 30px;
  }
  .header-copyright {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .v-app-bar {
    font-size: 12px;
  }

  .header-welcome {
    display: none;
  }

  .header-copyright {
    display: none;
  }
}
</style>

<script>
import { VHover, VListItem } from "vuetify/lib";
import { mapState, mapMutations } from "vuex";

import notificationsManager from "../apis/notifications";
import localStorageService from "../services/localStorageService";
import AvatarServices from "../services/avatars";

const packageInfo = require("../../package.json");

export default {
  name: "CoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.profile = [
      { title: this.$t("header-profile"), url: "/profile" },
      { divider: true },
      { title: this.$t("header-logout"), func: this.logout },
    ];
  },

  data: () => ({
    profile: [],
    notification_snackbar: false,
    notification_timeout: 60000,
    notification: null,
    notification_severity: "primary",
    incCounterRecomputer: 0,
    packageInfo: packageInfo,
    background_image: require("../assets/header.jpg"),
  }),

  methods: {
    buildGravatar: function(notification) {
      return (
        "<img style='width:48px; border:1px solid black; border-radius: 50%;' src='data:image/jpeg;base64," +
        notification.gravatar +
        "'>"
      );
    },

    checkGravatar: function(notification) {
      return new Promise((resolve, reject) => {
        if (!notification.gravatarFetched) {
          notification.gravatarFetched = true;
          AvatarServices.fetchAvatar(notification.avatar_id)
            .then((result) => {
              notification.gravatar = result.avatar;
              resolve();
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          resolve();
        }
      });
    },

    handleClick: function(item) {
      if (item.func) item.func();
      else if (item.url) {
        if (item.url != this.$router.currentRoute.path)
          this.$router.push(item.url);
      }
    },

    closeNotification() {
      this.notification_snackbar = false;
      this.notification = null;
    },

    ackNotification() {
      notificationsManager
        .ackNotification(this.notification.id, false)
        .then(() => {
          this.notification.ref.acked = true;
          localStorageService.setNotifications(
            JSON.stringify(this.$store.state.user.notifications)
          );
          this.incCounterRecomputer++;
          this.closeNotification();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    removeNotification() {
      notificationsManager
        .ackNotification(this.notification.id, true)
        .then(() => {
          for (
            let n = 0;
            n < this.$store.state.user.notifications.length;
            n++
          ) {
            if (
              this.$store.state.user.notifications[n].id == this.notification.id
            ) {
              this.$store.state.user.notifications.splice(n, 1);
              break;
            }
          }

          localStorageService.setNotifications(
            JSON.stringify(this.$store.state.user.notifications)
          );
          this.incCounterRecomputer++;
          this.closeNotification();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    showNotification(notification) {
      this.notification_snackbar = true;
      this.notification = notification;
      if (notification.severity == 0) this.notification_severity = "orange";
      else if (notification.severity == 1)
        this.notification_severity = "purple";
      else if (notification.severity == 2) this.notification_severity = "green";
    },

    processNotification(notification) {
      if (!notification.valid) return;
      if (notification.avatar_id) {
        this.checkGravatar(notification)
          .then(() => {
            this.showNotification(notification);
          })
          .catch((err) => {
            console.log(err);
            this.showNotification(notification);
          });
      } else {
        this.showNotification(notification);
      }
    },

    hasValidNotifications: function() {
      if (
        !this.notifications ||
        !this.notifications.length ||
        !this.notifications[0].valid
      )
        return false;
      else return true;
    },

    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),

    logout: function() {
      this.$store
        .dispatch("logout")
        .then(() => {
          this.$router.push("/login");
        })
        .catch(() => {
          this.$router.push("/login");
        });
    },
  },

  computed: {
    ...mapState(["drawer"]),
  },
};
</script>
