<template>
  <v-dialog
    persistent
    overlay-opacity="0.6"
    max-width="800"
    :value="openDialog"
  >
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1"
                  ><v-icon large>mdi-comment-question-outline</v-icon></v-col
                >
                <v-col align="center" cols="10" style="font-size: 20px"
                  >Data documenti</v-col
                >
                <v-col cols="1">
                  <v-btn icon>
                    <v-icon @click="closeDialog" large>mdi-close</v-icon>
                  </v-btn></v-col
                >
              </v-row>
            </template>

            <v-card-text>
              <span style="font-size:20px;"
                >Seleziona la data di fatturazione</span
              >
              <v-row dense align="center" justify="center">
                <v-col>
                  <div class="flex-center">
                    <v-radio-group
                      v-model="date_type"
                      row
                      class="justify-center"
                    >
                      <v-radio label="Oggi" value="0"></v-radio>
                      <v-radio label="Data transazione" value="1"></v-radio>
                      <v-radio label="Specifica" value="2"></v-radio>
                    </v-radio-group>
                  </div>
                </v-col>

                <v-col>
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="date_type != '2'"
                        v-model="dateFormatted"
                        label="Data"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        @blur="date = parseDate(dateFormatted)"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      locale="it-it"
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="text-center">
                <v-col>
                  <v-btn
                    dark
                    width="120"
                    @click="confirm()"
                    class="ma-2"
                    color="primary"
                    >Conferma</v-btn
                  >
                  <v-btn
                    dark
                    width="120"
                    @click="dismiss()"
                    class="ma-2"
                    color="secondary"
                    >Annulla</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      openDialog: false,
      resolve: null,
      reject: null,
      date_type: "1",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateFormatted: this.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      menu1: false,
      transaction_date: null,
    };
  },

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(val);
    },
    date_type(val) {
      if (val == 0) {
        this.date = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
      } else if (val == 1) {
        this.date = new Date(Date.parse(this.transaction_date))
          .toISOString()
          .substr(0, 10);
      }
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
    },

    show(transaction_date) {
      this.date_type = "1";

      this.menu1 = false;

      return new Promise((res, rej) => {
        this.resolve = res;
        this.reject = rej;
        this.openDialog = true;
        this.transaction_date = transaction_date;
        this.date = new Date(Date.parse(this.transaction_date))
          .toISOString()
          .substr(0, 10);
        this.dateFormatted = this.formatDate(this.date);
      });
    },

    hide() {
      this.openDialog = false;
      this.reject();
    },

    confirm() {
      this.openDialog = false;
      this.resolve(this.date);
    },

    dismiss() {
      this.hide();
    },

    closeDialog() {
      this.hide();
    },
  },
};
</script>

<style scoped>
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
