import axios from "axios";
import config from "../config";
import localStorageService from "../services/localStorageService";

let invoices = new Object({
  

  downloadInvoicesXMLZip: ctx => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      if (ctx.currentPage) delete ctx.currentPage;
      if (ctx.page) delete ctx.page;
      if (ctx.perPage) delete ctx.perPage;

      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/invoices/xmlinvoices?ctx=" +
          encodeURIComponent(JSON.stringify(ctx)) +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  downloadInvoiceXML: (doc_num, doc_year) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/invoices/invoice-" +
          encodeURIComponent(doc_num + "_" + doc_year) +
          ".xml?doc_num=" +
          encodeURIComponent(doc_num) +
          "&doc_year=" +
          encodeURIComponent(doc_year) +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  downloadInvoiceById: id => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/invoices/invoices-" +
          id.join("-") +
          ".zip?id=" +
          encodeURIComponent(id) +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  downloadInvoice: (doc_num, doc_year) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/invoices/invoice-" +
          encodeURIComponent(doc_num + "_" + doc_year) +
          ".pdf?doc_num=" +
          encodeURIComponent(doc_num) +
          "&doc_year=" +
          encodeURIComponent(doc_year) +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  archiveTransaction: transactions => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/invoices/archiveTransaction",
        data: { transactions: transactions },
        method: "POST"
      })
        .then(res => {
          resolve(res.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateData: transaction => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/invoices/updateTransaction",
        data: { transaction: transaction },
        method: "POST"
      })
        .then(res => {
          resolve(res.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  unarchiveTransaction: transaction_id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/invoices/unarchiveTransaction",
        data: { transaction_id: transaction_id },
        method: "POST"
      })
        .then(res => {
          resolve(res.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  deleteInvoice: transaction_id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/invoices/deleteInvoice",
        data: { transaction_id: transaction_id },
        method: "POST"
      })
        .then(res => {
          resolve(res.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  restoreInvoice: transaction_id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/invoices/restoreInvoice",
        data: { transaction_id: transaction_id },
        method: "POST"
      })
        .then(res => {
          resolve(res.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  restoreTransaction: transaction_id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/invoices/restoreTransaction",
        data: { transaction_id: transaction_id },
        method: "POST"
      })
        .then(res => {
          resolve(res.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  generateInvoices: (transactions_ids, date) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/invoices/generateInvoices",
        data: { transactions_ids: transactions_ids, date: date },
        method: "POST"
      })
        .then(res => {
          resolve(res.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  reserveNumbering: amount => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/invoices/reserveNumbering",
        data: { amount: amount },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  setNextValue: value => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/invoices/setNextValue",
        data: { value: parseInt(value) + 1 },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchTransactions: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/invoices/transactions",
        data: { ctx: ctx },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchEuCodes: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/invoices/fetchEuCodes",
        data: { ctx: ctx },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchNextInvoice: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/invoices/fetchNextInvoice",
        data: {},
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
});

export default invoices;
