const crypto = require("crypto");
const request = require("request");
import userManager from "../apis/users";

const AvatarServices = (function() {
  return {
    gravatarCache: {},
    avatarCache: {},
    fetchingGravatar: {},

    removeAvatarFromCache: function(id) {
      if (id in AvatarServices.avatarCache)
        delete AvatarServices.avatarCache[id];
    },

    fetchAvatar: function(id) {
      return new Promise(function(resolve, reject) {
        if (id) {
          if (id in AvatarServices.avatarCache) {
            resolve(AvatarServices.avatarCache[id]);
          } else {
            if (!AvatarServices.fetchingGravatar[id]) {
              AvatarServices.fetchingGravatar[id] = [];
              userManager
              .fetchAvatar(id)
              .then((result) => {
                if (result) {
                  AvatarServices.avatarCache[id] = result;
                  let items = AvatarServices.fetchingGravatar[id];
                  for (let n = 0; n < items.length; n++) {
                    items[n].resolve(result);
                  }
                  delete AvatarServices.fetchingGravatar[id];
                  resolve(result);
                } else {
                  let items = AvatarServices.fetchingGravatar[id];
                  for (let n = 0; n < items.length; n++) {
                    items[n].reject();
                  }
                  delete AvatarServices.fetchingGravatar[id];
                  reject();
                }
              })
              .catch((err) => {
                console.log(err);
                reject(err);
              });
              // We are not fetching the same gravatar, proceed with the request
            } else {
              // Push the promise in the queue , it will be triggere once the gravatar is fetched
              AvatarServices.fetchingGravatar[id].push ({
                resolve : resolve,
                reject : reject
              })
            }
          }
        } else {
          reject("Invalid id");
        }
      });
    },

    fetchGravatar: function(email) {
      return new Promise(function(resolve /*, reject*/) {
        if (email in AvatarServices.gravatarCache) {
          resolve(AvatarServices.gravatarCache[email]);
        } else {
          var hash = crypto
            .createHash("md5")
            .update(email.toLowerCase())
            .digest("hex");
          let url = "https://www.gravatar.com/avatar/" + hash + "?s=256&d=404";
          var requestSettings = {
            url: url,
            method: "GET",
            encoding: null,
          };
          request(requestSettings, function(err, response /*,body*/) {
            let avatar = null;
            if (!err && response.statusCode != 404) {
              avatar = response.body.toString("base64");
              AvatarServices.gravatarCache[email] = avatar;
              resolve(avatar);
            } else {
              AvatarServices.gravatarCache[email] = "";
              resolve();
            }
          });
        }
      });
    },
  };
})();

export default AvatarServices;
